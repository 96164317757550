import React from 'react';
import { SiteLayout, Head } from '~/components';
import * as styles from '~/styles/privacy.module.scss';

const PrivacyPage = () => {

  return (
    <SiteLayout>
      <Head title="個人情報保護方針" />

      <div className={styles.hero}>
        <h1 className={styles.hero__title}>個人情報保護方針</h1>
      </div>

      <main className={styles.main}>
        <hr className={styles.divider} />
        <section className={styles.section}>
          <h2 className={styles.title}>【個人情報の取り扱いについて】</h2>
          <h3 className={styles.heading}>1.個人情報保護管理体制の確立</h3>
          <p className={styles.text}>当社は、事業活動においてお預かりした個人情報を保護するための管理体制を確立します。</p>
          <h3 className={styles.heading}>2.個人情報の取得・利用・提供</h3>
          <p className={styles.text}>
            提供される個人情報は、次に記された目的のために当社の正当な事業範囲内で利用いたします。<br />
            また、法令等に基づかない目的外利用を禁止するとともに、そのための処置を講じます。<br />
            ・お客様とのお取引・ご契約を円滑に履行するため<br />
            ・お客様からのお問い合わせに迅速に対応するため
          </p>
          <h3 className={styles.heading}>3.法令・規範の順守</h3>
          <p className={styles.text}>当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守するとともに、当社の個人情報管理規則をこれらの法令及び指針、その他の規範に適合させます。</p>
          <h3 className={styles.heading}>4.安全管理の実施</h3>
          <p className={styles.text}>当社は、個人情報の正確性及び安全性を確保するため、想定されるリスクに見合う合理的な対策として、個人情報へのアクセス管理、個人情報の持ち出し手段の制限、外部からの不正アクセス防止策等を実施し、個人情報の漏洩、滅失又は毀損の防止に努めます。これらの安全策は定期的に見直すとともに、是正を行います。</p>
          <h3 className={styles.heading}>5.個人情報に関する開示等、苦情および相談への対応</h3>
          <p className={styles.text}>当社は、個人情報に関して当社が公表した手続きに基づいて本人から自己の情報の開示、訂正・追加・削除、または利用若しくは提供の拒否、並びに苦情及び相談の申し出を受けた場合、適切な本人確認を実施した上、すみやかに対応します。</p>
          <h3 className={styles.heading}>6.個人情報保護マネジメントシステムの策定・実施・維持および継続的改善</h3>
          <p className={styles.text}>当社は、当社で従事する役職員に個人情報保護の重要性を認識させ、個人情報を適切に利用し、保護するための個人情報保護マネジメントシステムを策定し、これを着実に実施、監視、維持し、継続的に改善します。</p>
          <p className={styles.text_right}>
            2012年04月01日 制定<br />
            2012年09月26日 改定<br />
            株式会社会津ラボ<br />
            代表取締役　松永 州央
          </p>
        </section>
        <hr className={styles.divider} />
        <section className={styles.section}>
          <h2 className={styles.title}>プライバシーポリシー</h2>
          <p className={styles.text}>当社では、個人情報保護方針に基づき、個人情報の取り扱いについて細心の注意をもって管理しています。個人情報に関する当社の考え方ならびに、ご留意いただきたい事柄を以下に記載します。</p>
          <h3 className={styles.heading}>個人情報取り扱いに関する体制</h3>
          <p className={styles.text}>当社では情報セキュリティー委員会を設置し、個人情報の適切な収集などの各種規定を定めています。また、部門ごとに情報セキュリティー委員を配し、委員からの社内への啓蒙も含め、日々、社内全体が個人情報の取り扱いに対しての認識を高めています。</p>
          <h3 className={styles.heading}>個人情報の収集と利用について</h3>
          <p className={styles.text}>当社が運営するサイトにおいて、必要に応じてお客様の個人情報（氏名、電話番号、電子メールアドレス、プロフィールなど）を収集することがありますが、それらの情報に関しては、お客様より情報のご提供をいただく際に提示されている目的（サービス提供、懸賞、アンケート、採用への応募、ご希望の資料発送、問い合わせ・苦情への対応など）以外に使用することはありません。ただし、お客様個人を識別できない状態に加工した統計情報は、当社の新サービス開発のための参考資料として利用する場合があります。</p>
          <h3 className={styles.heading}>個人情報の保管について</h3>
          <p className={styles.text}>
            当社では、お客様の個人情報の管理を徹底するため、適切な内部管理体制を構築し、厳重に保管・管理します。なお、当社に保管する個人情報につきましては、当社規定の期間中、保管することとします。個人情報の提供 お客様にご提供頂きました個人情報につきまして、下記の場合については個人情報を開示することがあります。<br />
            ・ご本人様の同意を得た場合<br />
            ・お客様にお知らせした使用目的のために、事業協力会社に対する開示が必要な場合（例えば、アンケートにご協力頂いた場合、集計分析などを外部業者に委託することがあります。その場合、当該の委託・協力会社には、当社の情報保護に対する方針の観点から妥当と判断される企業を選定します。また、個人情報の管理体制についても十分注意を促し、目的以外に使用されないことを確認します）<br />
            ・法令に基づく場合<br />
            ・裁判所、警察またはこれらに準じる公的機関より、適法に開示を要請された場合<br />
            ・人の生命、身体または財産の保護の必要がある場合に、お客様の同意を得ることが困難であるとき<br />
            ・国の機関もしくは地方公共団体またはその委託を受けた者が、法令の定める事務を遂行することに対して協力する必要がある場合に、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき<br />
            ・統計的なデータとして、お客様個人を識別できない状態に加工した場合（例：アプリコンテンツの年代別人数など）
          </p>
          <h3 className={styles.heading}>個人情報の外部委託</h3>
          <p className={styles.text}>お預かりした個人情報は、情報処理などの目的で外部に委託することがあります。外部委託先につきましては、当社が設定する個人情報の委託先評価基準に照らし、適切な管理水準を維持している委託先にのみ委託し、委託期間中は適切な管理を行います。個人情報の管理基準が、当社が設定する安全対策水準以上である企業のみを選定し、適切に管理・監督を行います。 個人情報の変更・取り消しなどについて 個人情報をご提供されたご本人から個人情報の内容確認、訂正（追加・削除）、利用停止（消去）、第三者提供の停止などのお申し出があった場合には、適切に対応します。また、苦情及びお問い合わせにも対応します。 ただし、当社が運営するコンテンツの解約につきましては、お客様ご本人の携帯電話よりコンテンツ解約の手続きを実行してください。</p>
          <h3 className={styles.heading}>クッキー（Cookie）について</h3>
          <p className={styles.text}>
            当サイトでは、ブラウザを開いている間だけ保存され、サイト閲覧中に利用されるCookie（セッションクッキー）を使用します。「Cookie」とは、個人情報の代わりに任意の文字が書かれたテキストファイル（記号）のことで、当社サイトを訪れた時に、当社サイトのウェブサーバーとお客様のインターネット閲覧ソフト（ブラウザ）との間でやりとりし、お客様のパソコン内に保存されるものです。これにより、いっそう便利なサービス提供が受けられるようになります。当社ではお客様がより快適なサービスを受けられるために「Cookie」を利用しており、それ以外の目的で使用することはありません（「Cookie」の中に は個人を特定する情報は含まれていません）。<br />
            お客様は、お客様のブラウザの設定により、事前に「Cookie」使用サイトであることを表示したり、受け取りを拒否することができます。お客様が「Cookie」を使用しない設定としている場合、一部のサービスにおいて機能に不自由が生じることがありますので、ご了承ください。
          </p>
          <h3 className={styles.heading}>Google Analytics について</h3>
          <p className={styles.text}>当サイトでは、サービス向上のためGoogle, Inc.のGoogle Analyticsを利用してサイトの計測を行っております。これに付随して生成されるテキストファイル「Cookie」を通じて分析を行うことがありますが、この際、IPアドレス等のユーザ様情報の一部が、Google, Inc.に収集される可能性があります。サイト利用状況の分析、サイト運営者へのレポートの作成、その他のサービスの提供目的に限りこれを使用します。利用者は、本サイトを利用することで、上記方法および目的においてGoogleが行うこうしたデータ処理につき許可を与えたものとみなします。なお、この機能はCookieを無効にすることで収集を拒否することが出来ます。お使いのブラウザの設定をご確認ください。この規約に関して、詳しくはGoogleアナリティクス利用規約をご確認ください。</p>
          <h3 className={styles.heading}>広告の配信について</h3>
          <p className={styles.text}>当サイトは第三者配信の広告サービス「Google Adsense グーグルアドセンス」を利用しています。広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は「広告 – ポリシーと規約 – Google」をご覧ください。</p>
        </section>
        <hr className={styles.divider} />
      </main>

    </SiteLayout>
  )
}

export default PrivacyPage;
